import { AxiosRequestConfig } from 'axios';

import { querify } from '@/shared/utils/querify';

import { axiosClientArentaClass } from '../arenta/axios';

import { apiService } from './api-service';
import { TResponseAndRequest } from './types';

export type TReadParams<P> = {
    params?: P & TWithOrder;
    headers?: { [key: string]: string };
    baseURL?: string;
};

export type TParamsPropertiesArray = {
    [key: string]: { [key: string]: number | string }[];
};

export type TErrorData = {
    message: string;
    violations: string[];
};

export type TReadParamsProperties = {
    [key: string]:
        | string[]
        | string
        | number
        | boolean
        | undefined
        | null
        | number[]
        | { [key: string]: number | string };
};

type TReadOneParams<P> = {
    identifier: string | number;
    params?: P & TWithOrder;
    baseURL?: string;
    headers?: { [key: string]: string };
    options?: { [key: string]: string };
};

type TCreateParams<P> = {
    data: P;
    params?: P;
    headers?: { [key: string]: string };
};

type TUpdateParams<P> = {
    identifier: string | number;
    data: P;
    params?: P;
    headers?: { [key: string]: string };
};

type TDeleteParams<P> = {
    headers?: { [key: string]: string };
    identifier: string | number;
    params?: AxiosRequestConfig<P>;
};

type TCommandParams<P> = {
    command: string;
    params?: P;
    data?: P;
    headers?: { [key: string]: string };
    method?: 'GET' | 'POST' | 'PATCH';
    baseURL?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: { [key: string]: any };
};

type TWithOrder = {
    order?: Record<string, 'desc' | 'asc'>;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const ResourceService = (
    entity: string,
    axiosClient = axiosClientArentaClass,
    context?: TResponseAndRequest,
) => {
    axiosClient.setInterceptors(context);

    // TODO 3 ким проверить read create итд и если все норм то расширить от TReadParamsProperties
    return {
        read: <T, P = undefined>({ params, headers, baseURL }: TReadParams<P> = {}): Promise<T> => {
            const query = params ? querify<P>(params) : '';

            const uri = `/${entity}${query}`;

            return apiService(axiosClient).get<T>(uri, headers, baseURL);
        },
        readOne: <T, P = TReadParamsProperties>({
            identifier,
            params,
            headers,
            baseURL,
            options,
        }: TReadOneParams<P>): Promise<T> => {
            const query = params ? querify<P>(params) : '';

            const uri = `/${entity}/${identifier}${query}`;

            return apiService(axiosClient).get<T>(uri, headers, baseURL, options);
        },
        create: <T, P>({ data, headers, params }: TCreateParams<P>): Promise<T> => {
            const query = params ? querify<P>(params) : '';

            const uri = `/${entity}${query}`;

            return apiService(axiosClient).post<T, P>(uri, data, headers);
        },
        update: <T, P>({ identifier, data, headers }: TUpdateParams<P>): Promise<T> => {
            const uri = `/${entity}/${identifier}`;

            return apiService(axiosClient).put<T, P>(uri, data, headers);
        },
        patch: <T, P>({ identifier, data, headers, params }: TUpdateParams<P>): Promise<T> => {
            const cmd = `/${entity}/${identifier}`;

            const query = params ? querify<P>(params) : '';

            const uri = `${cmd}${query}`;

            return apiService(axiosClient).patch<T, P>(uri, data, headers);
        },
        delete: <T, P>(params: TDeleteParams<P>): Promise<T> => {
            const { identifier, params: p } = params;

            const uri = `/${entity}/${identifier}`;

            return apiService(axiosClient).delete<T>(uri, p);
        },
        command: <T, P = TReadParamsProperties>({
            command,
            params,
            data,
            headers,
            method = 'POST',
            baseURL,
            config,
        }: TCommandParams<P>): Promise<T> => {
            const cmd = `/${entity}/${command}`;

            const query = params ? querify<P>(params) : '';

            const uri = `${cmd}${query}`;

            if (method === 'POST') return apiService(axiosClient).post<T, P>(uri, data, headers, config);

            return apiService(axiosClient).get<T>(uri, headers, baseURL, config);
        },
    };
};
