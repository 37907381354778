import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 16px);
    width: calc(100vw - 16px);

    .content {
        font-family: 'Rounded Mplus 1c', sans-serif;
        display: flex;
        flex-direction: column;
        gap: 52px;
        justify-content: center;
        align-items: center;

        .text {
            text-align: center;
            width: 540px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;

            ${mq.lt.md} {
                h1 {
                    font-size: 18px;
                }

                width: 100%;
            }
            ${mq.lt.sm} {
                h1 {
                    font-size: 16px;
                }
            }
        }
    }
`;
