import { styled } from 'styled-components';

import { rowBothMiddle } from '@/shared/ui/styled';

export const Wrapper = styled.div`
    ${rowBothMiddle};
    flex-direction: column;
`;

export const SmileWrapper = styled.span`
    margin-bottom: 32px;
    position: relative;
    display: inline-block;
    width: 78px;
    height: 78px;
`;

export const MessageWrapper = styled.div`
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 16px;
    text-align: center;
`;
