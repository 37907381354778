import { styled } from 'styled-components';

import { theme } from '@/shared/theme';
import { mq } from '@/shared/ui/styled';

import { TSize } from './heading';

export const StyledHeading = styled.h1<{
    $size: string;
    $center?: boolean;
    $colorElement: string;
    $sizeMobile: TSize;
    $marginB?: string;
    $marginBMobile?: string;
    $weight?: string;
}>`
    color: ${({ $colorElement }) => ($colorElement ? $colorElement : theme.color.dark)};
    font-size: ${(props) => props.$size};
    font-style: normal;
    font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
    line-height: 120%;
    ${({ $marginB }) => $marginB && `margin-bottom: ${$marginB}px`};
    text-align: ${({ $center }) => ($center ? 'center' : 'left')};

    ${mq.lt.md} {
        font-size: ${({ $sizeMobile }) => theme.fontSize[$sizeMobile]};
        ${({ $marginBMobile }) => $marginBMobile && `margin-bottom: ${$marginBMobile}px`};
    }
`;
