import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TRentProduct, getRentProducts } from '@/entities/product';

import { STORAGE_KEYS } from '@/shared/constants/storage-keys';
import { LocalStore } from '@/shared/utils/local-store';

const loadBasketProducts = createAsyncThunk<TRentProduct[]>(
    'basket/loadProducts',
    async function (_, { rejectWithValue }) {
        try {
            const ids: string[] = LocalStore.get(STORAGE_KEYS.basketProducts);

            if (Array.isArray(ids) && ids.length > 0) {
                const responseProducts = await getRentProducts({ ids });

                return responseProducts.data;
            }

            return [];
        } catch (err) {
            /* eslint-disable no-console */
            console.error('loadBasketProducts error', err);

            const error = err as AxiosError;

            captureException(error);

            return rejectWithValue(error);
        }
    },
);

export { loadBasketProducts };
