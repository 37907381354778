import { LocalStore } from '@/shared/utils/local-store';

// Ключ истории переходов в LocalStorage
const HISTORY_URL_STORAGE_KEY = 'history_url';

// Ссылки сайта, которые в историю не должны попадать
const DISABLED_URLS = ['/sign-in', '/registration'];

type HistoryType = {
    current: string;
    prev: string;
};

export const setHistoryUrl = (path: string): void => {
    const history = LocalStore.get(HISTORY_URL_STORAGE_KEY) as HistoryType;

    const isDisabledUrl = DISABLED_URLS.includes(path);

    if (path === history?.current || isDisabledUrl) {
        return;
    }

    const data = {
        current: path,
    };

    LocalStore.set(HISTORY_URL_STORAGE_KEY, data);
};

export const getHistoryLastUrl = (): string => {
    return (LocalStore.get(HISTORY_URL_STORAGE_KEY) as HistoryType)?.current || '/';
};
