import { TContentBlock } from '@/store/reducers/content-blocks/types';

import { ResourceService, TReadParamsProperties } from '../base-services/resource-service';
import { TMeta } from '../base-services/types';

export const ContentBlockService = ResourceService('content-blocks');

type ResponseTypeContent = {
    meta: TMeta;
    data: TContentBlock[];
};

export const getContentBlocks = async (locale: string): Promise<TContentBlock[]> => {
    const { data } = await ContentBlockService.read<ResponseTypeContent, TReadParamsProperties>({
        params: {
            locale,
        },
    });

    return data;
};
