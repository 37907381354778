import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TRentProduct, getRentProducts } from '@/entities/product';

import { STORAGE_KEYS } from '@/shared/constants/storage-keys';
import { LocalStore } from '@/shared/utils/local-store';

const loadFavoriteProducts = createAsyncThunk<TRentProduct[], undefined, { rejectValue: unknown }>(
    'favorite/loadProducts',
    async function (_, { rejectWithValue }) {
        try {
            const ids: string[] = LocalStore.get(STORAGE_KEYS.favoriteProducts);

            if (Array.isArray(ids) && ids.length > 0) {
                const responseProducts = await getRentProducts({ ids });

                return responseProducts.data;
            }

            return [];
        } catch (err) {
            const error = err as AxiosError;
            captureException(error);

            return rejectWithValue(error);
        }
    },
);

export { loadFavoriteProducts };
