import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { ILoadPopularProductsPayload } from '@/store/reducers/rent/types';
import { setTechnicalError } from '@/store/reducers/settings/slice';

import { TCategoryProduct, TRentProduct, getRentProducts, getAllCategories, EStatusProduct } from '@/entities/product';

export const loadPopularProducts = createAsyncThunk<
    TRentProduct[],
    ILoadPopularProductsPayload,
    { rejectValue: unknown }
>('rent/loadPopularProducts', async function ({ cityKatoid }: ILoadPopularProductsPayload, { rejectWithValue }) {
    try {
        const { data } = await getRentProducts({
            statuses: [EStatusProduct.ACTIVE],
            popular: true,
            katoid: cityKatoid,
        });
        return data;
    } catch (err) {
        console.error('loadPopularProducts error', err);
        const error = err as AxiosError;
        captureException(error);

        return rejectWithValue(error);
    }
});

export const loadAllCategories = createAsyncThunk<TCategoryProduct[], undefined>(
    'rent/loadAllCategories',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            return await getAllCategories();
        } catch (err) {
            console.error('loadAllCategories error', err);
            const error = err as AxiosError;
            captureException(error);
            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
