import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TCity } from '@/components/modules/choose-city';
import { citiesArray } from '@/mock/cities-array';
import {
    loadUserData,
    requestGetUserByINN,
    requestLoginUser,
    requestGetLead,
    requestVerifyCounterparty,
    loadCheckIsNewQuestionnaireAllowed,
} from '@/store/reducers/user/action-creators';
import { TUserModelState } from '@/store/reducers/user/types';
import { TUserProfileData } from '@/types/user';

import { TResponseAndRequest } from '@/shared/api';
import { UNCAUGHT_COMMON_TEXT_ERROR } from '@/shared/constants/settings';
import { STORAGE_KEYS } from '@/shared/constants/storage-keys';
import { Cookies } from '@/shared/utils/cookie';

const initialState: TUserModelState = {
    data: null,
    auth: {
        authenticated: false,
        isUserLead: false,
    },
    loadingData: false,
    requestingIsCounterparty: false,
    requestingCounterpartyCodeError: null,
    requestingCounterpartyCodeSuccess: false,
    requestingLeadCodeError: null,
    requestingLeadCodeSuccess: false,
    iinUser: '',
    loggingUserInSuccess: null,
    loggingUserInError: '',
    isFindUserByIin: null,
    isRecoveryLead: false,
    cityUser: {
        id: 0,
        name: '',
        katoid: '',
        // eslint-disable-next-line camelcase
        name_kz: '',
        slug: '',
    },
    isFindUserByIinError: null,
    errorMessageFromFindUserByIin: '',
};

export const userSlice = createSlice({
    name: 'user', //FIXME maybe should be stored in constants
    initialState,
    reducers: {
        loginUser: (state) => {
            state.auth.authenticated = true;
        },
        restoreCounterpartyStatuses: (state) => {
            state.requestingCounterpartyCodeError = null;

            state.requestingCounterpartyCodeSuccess = false;

            state.requestingIsCounterparty = false;
        },
        restoreLeadStatuses: (state) => {
            state.requestingLeadCodeError = null;

            state.requestingLeadCodeSuccess = false;
        },
        setUserInn: (state, action) => {
            state.iinUser = action.payload.iin;
        },
        resetUserData: (state) => {
            state.data = null;

            state.auth.authenticated = false;

            state.auth.isUserLead = false;

            state.isRecoveryLead = false;

            state.loadingData = false;

            state.requestingIsCounterparty = false;

            state.requestingCounterpartyCodeError = null;

            state.requestingCounterpartyCodeSuccess = false;

            state.requestingLeadCodeError = null;

            state.requestingLeadCodeSuccess = false;

            state.iinUser = '';

            state.loggingUserInSuccess = null;

            state.loggingUserInError = '';

            state.isFindUserByIin = null;

            state.isRecoveryLead = false;

            state.isNewQuestionnaireAllowed = undefined;
        },
        setRecoveryLead: (state) => {
            state.isRecoveryLead = true;
        },
        resetLoginUserStatus: (state) => {
            state.loggingUserInSuccess = null;
        },
        resetCheckingUserByIinStatus: (state) => {
            state.isFindUserByIin = null;

            state.isFindUserByIinError = null;
        },
        loadCityUser: (state, action: PayloadAction<TResponseAndRequest | undefined>) => {
            const context = action.payload;

            const city = Cookies.get(STORAGE_KEYS.userCity, context) as TCity;

            if (city) {
                state.cityUser = city;
            } else {
                state.cityUser = citiesArray[0];
            }
        },
        setCityUser: (state, action: PayloadAction<TCity>) => {
            const cityUserObj = action.payload;

            state.cityUser = cityUserObj;

            Cookies.set(STORAGE_KEYS.userCity, cityUserObj);
        },
    },
    extraReducers: (builder) => {
        builder
            // Processing register request
            .addCase(loadUserData.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(loadUserData.rejected, (state) => {
                state.loadingData = false;

                state.data = null;

                state.auth.authenticated = false;

                state.auth.isUserLead = false;
            })
            .addCase(loadUserData.fulfilled, (state, action) => {
                // ARKZ-374 убрать тайп кастинг
                const data = action.payload as TUserProfileData;

                state.loadingData = false;

                // Customer
                if (data?.name?.first) {
                    state.loadingData = false;

                    state.data = data;

                    state.auth.authenticated = true;

                    state.auth.isUserLead = false;
                }

                // Lead
                if (data?.id) {
                    state.auth.authenticated = true;

                    state.auth.isUserLead = true;

                    state.data = data;
                }
            })
            .addCase(requestVerifyCounterparty.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(requestVerifyCounterparty.rejected, (state) => {
                state.requestingCounterpartyCodeError = UNCAUGHT_COMMON_TEXT_ERROR;

                state.loadingData = false;
            })
            .addCase(requestVerifyCounterparty.fulfilled, (state, { payload }) => {
                state.loadingData = false;

                if (payload.found === 'customer') {
                    state.requestingIsCounterparty = true;
                }

                state.requestingCounterpartyCodeSuccess = true;
            })
            .addCase(requestGetLead.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(requestGetLead.rejected, (state, action) => {
                state.requestingLeadCodeError = action.payload?.message || '';

                state.loadingData = false;
            })
            .addCase(requestGetLead.fulfilled, (state) => {
                state.loadingData = false;

                state.requestingLeadCodeSuccess = true;
            })
            .addCase(requestGetUserByINN.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(requestGetUserByINN.rejected, (state, action) => {
                state.loadingData = false;

                state.isFindUserByIinError = true;

                state.errorMessageFromFindUserByIin = action.payload?.message || '';
            })
            .addCase(requestGetUserByINN.fulfilled, (state, { payload }) => {
                state.loadingData = false;

                if (payload.exists === true) {
                    state.iinUser = payload.login;

                    state.isFindUserByIin = true;
                } else {
                    state.isFindUserByIin = false;
                }
            })
            .addCase(requestLoginUser.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(requestLoginUser.rejected, (state, action) => {
                state.loadingData = false;

                state.loggingUserInSuccess = false;

                state.loggingUserInError = action.payload?.message || '';
            })
            .addCase(requestLoginUser.fulfilled, (state) => {
                state.loggingUserInSuccess = true;

                state.auth.authenticated = true;
            })
            .addCase(loadCheckIsNewQuestionnaireAllowed.pending, (state) => {
                state.loadingData = true;
            })
            .addCase(loadCheckIsNewQuestionnaireAllowed.rejected, (state) => {
                state.loadingData = false;
            })
            .addCase(loadCheckIsNewQuestionnaireAllowed.fulfilled, (state, action) => {
                state.loadingData = false;

                state.isNewQuestionnaireAllowed = action.payload;
            });
    },
});

export const {
    loginUser,
    setCityUser,
    setUserInn,
    loadCityUser,
    resetLoginUserStatus,
    resetCheckingUserByIinStatus,
    resetUserData,
    restoreCounterpartyStatuses,
    restoreLeadStatuses,
    setRecoveryLead,
} = userSlice.actions;

export default userSlice.reducer;
