import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { Heading } from '@/shared/ui/heading';
import { RefreshIcon } from '@/shared/ui/icons';

import { Wrapper } from './styled';

export const DeniedLocalStorage = () => {
    const theme = useTheme();
    const { reload } = useRouter();
    const { t } = useTranslate();

    return (
        <Wrapper>
            <div className="content">
                <div className="text">
                    <Heading size={theme.fontSize.lg}>{t('Мы не смогли загрузить страницу 😥')}</Heading>
                    <span>
                        {t(
                            'Пожалуйста, проверьте настройки Вашего браузера и убедитесь, что куки (cookies) и локальное хранилище (localStorage) включены.',
                        )}
                    </span>
                </div>
                <Button variant="dark" icon={<RefreshIcon />} onClick={reload}>
                    {t('Обновить страницу')}
                </Button>
            </div>
        </Wrapper>
    );
};
