import { FC, ReactNode } from 'react';

import { useAppDispatch } from '@/store/hooks';
import { closeModal } from '@/store/reducers/modal/slice';

import { Button } from '@/shared/ui/button';

import * as Styled from './styled';

type TProps = {
    smileChar?: string;
    content: string | ReactNode;
    textBtn: string;
    onClick?: () => void;
};

export const MessageInfoSmileErrorModal: FC<TProps> = ({ content, textBtn, onClick, smileChar }) => {
    const dispatch = useAppDispatch();

    const handleCloseModal = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
        dispatch(closeModal());
    };

    return (
        <Styled.Wrapper>
            <div className="smile">{smileChar ?? '😢'}</div>
            <div className="message">{content}</div>
            <div className="action">
                <Button aria-label={textBtn} variant="primary" fontSize="md" stretch onClick={handleCloseModal}>
                    {textBtn}
                </Button>
            </div>
        </Styled.Wrapper>
    );
};
