import { axiosClientSCClass, TGetCitiesParams, TResponseCitiesWithMeta } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';

export const CitiesService = ResourceService('v1/cities', axiosClientSCClass);

export const getCities = async (params?: TGetCitiesParams): Promise<TResponseCitiesWithMeta> => {
    const responseProducts = await CitiesService.read<TResponseCitiesWithMeta, TGetCitiesParams>({
        params,
    });

    return responseProducts;
};
