import { TOrder, TOrderWithMeta } from '@/types/orders';
import { TUserProfileData } from '@/types/user';

import { TResponseAndRequest, TReadParamsProperties } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';
import { PRODUCT_CLASSIFIER_ID } from '@/shared/constants/settings';

import { axiosClientSCClass } from './axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomersService = (context?: TResponseAndRequest) =>
    ResourceService('v1/customers', axiosClientSCClass, context);

export type TIsNewQuestionnaireAllowed = {
    answer: boolean;
    reason: string;
};

export const checkIsNewQuestionnaireAllowed = async (
    context?: TResponseAndRequest,
): Promise<TIsNewQuestionnaireAllowed> => {
    return await CustomersService(context).command<TIsNewQuestionnaireAllowed, TReadParamsProperties>({
        command: 'me/is-new-questionnaire-allowed',
        params: {
            classifiers: [PRODUCT_CLASSIFIER_ID],
        },
        method: 'GET',
    });
};

export const getUserProfileData = async (context?: TResponseAndRequest): Promise<TUserProfileData> => {
    const responseDataUserProfile = await CustomersService(context).command<TUserProfileData, TReadParamsProperties>({
        method: 'GET',
        command: 'me',
        params: {
            expand: ['personal', 'six_months_amounts', 'signs'],
        },
    });

    return responseDataUserProfile;
};

export const updateUserProfileData = async (email: string, user: TUserProfileData): Promise<TUserProfileData> => {
    const month6Replenishment = user?.six_months_amounts?.replenishment;

    const month6Expenses = user?.six_months_amounts?.expenses;

    const responseDataUserProfile = await CustomersService().update<TUserProfileData, TReadParamsProperties>({
        identifier: 'me',
        data: {
            monthlyIncome: Number(user?.personal?.work.income?.monthly),
            otherSourcesIncome: Number(user?.personal?.work.income?.other_sources),
            aspRecipient: user?.signs?.is_asp_recipient,
            hasActual60daysDelay: user?.signs?.has_60_days_delay,
            amountExpenses6months: month6Expenses ? month6Expenses * 6 : 0,
            amountReplenishment6months: month6Replenishment ? month6Replenishment * 6 : 0,
            childrenAmount: Number(user?.personal?.children_amount),
            email,
        },
    });

    return responseDataUserProfile;
};

export const getActiveOrders = async (context?: TResponseAndRequest): Promise<TOrder[]> => {
    const responseOrders = await CustomersService(context).command<TOrderWithMeta, TReadParamsProperties>({
        method: 'GET',
        command: 'me/questionnaires',
        params: {
            expand: ['rentProduct', 'documents', 'repayments', 'jurist', 'prolongations', 'product', 'rentLeaseInfo'],
            states: ['active'],
            classifiersGroups: ['ARENTA'],
        },
    });

    return responseOrders.data;
};

export const getPreviousOrders = async (context?: TResponseAndRequest): Promise<TOrder[]> => {
    const responseOrders = await CustomersService(context).command<TOrderWithMeta, TReadParamsProperties>({
        method: 'GET',
        command: 'me/questionnaires',
        params: {
            expand: ['rentProduct', 'documents', 'repayments', 'prolongations'],
            states: ['closed'],
            classifiersGroups: ['ARENTA'],
        },
    });

    return responseOrders.data;
};
