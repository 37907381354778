import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const Wrapper = styled.div`
    background: ${({ theme }) => theme.background.mainBackground};
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 64px 32px;

    ${mq.lt.md} {
        padding: 32px;
    }

    .description,
    .contact {
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
    }

    .description {
        max-width: 418px;
        gap: 32px;

        .smile {
            font-size: 64px;
            font-weight: 500;
        }

        .title {
            font-size: 20px;
            font-weight: 500;
        }

        .text {
            font-size: 16px;
            font-weight: 400;
            margin-top: 16px;
        }
    }

    b {
        font-weight: 600;
    }
`;
