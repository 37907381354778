import { createSlice } from '@reduxjs/toolkit';

import { TRentState } from '@/store/reducers/rent/types';

import { getMainCategories } from '@/entities/product';

import { loadAllCategories, loadPopularProducts } from './action-creators';

const initialState: TRentState = {
    popularProducts: { data: [], isFetching: false, isLoaded: false },
    allCategories: { data: [], isFetching: false, isLoaded: false },
    mainCategories: { data: [], isFetching: false, isLoaded: false },
};

export const rentSlice = createSlice({
    name: 'rent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadPopularProducts.pending, (state) => {
                state.popularProducts.isFetching = true;

                state.popularProducts.isLoaded = false;
            })
            .addCase(loadPopularProducts.fulfilled, (state, action) => {
                state.popularProducts.isFetching = true;

                state.popularProducts.isLoaded = false;

                state.popularProducts.data = action.payload;
            });

        builder
            .addCase(loadAllCategories.pending, (state) => {
                state.allCategories.isFetching = true;

                state.mainCategories.isFetching = true;

                state.allCategories.isLoaded = false;

                state.mainCategories.isLoaded = false;
            })
            .addCase(loadAllCategories.rejected, (state) => {
                state.allCategories.isFetching = false;

                state.mainCategories.isFetching = false;

                state.allCategories.isLoaded = false;

                state.mainCategories.isLoaded = false;

                state.allCategories.data = [];

                state.mainCategories.data = [];
            })
            .addCase(loadAllCategories.fulfilled, (state, action) => {
                state.allCategories.isFetching = false;

                state.mainCategories.isFetching = false;

                state.allCategories.isLoaded = true;

                state.mainCategories.isLoaded = true;

                state.allCategories.data = action.payload;

                state.mainCategories.data = getMainCategories(action.payload);
            });
    },
});

export default rentSlice.reducer;
