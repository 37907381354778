import { TCreditProduct, TCreditProductWithMeta } from '@/types/credit-products';

import { ECreditProductType, PLATFORM_CODE, PRODUCT_TYPE } from '@/shared/constants/settings';

import { ResourceService } from '../base-services/resource-service';

import { axiosClientSCClass } from './axios';

export const CreditProductsService = ResourceService('v2/credit-products', axiosClientSCClass);

type TCreditProductBaseParams = {
    type: ECreditProductType;
    product: string;
};

type TParamsCreditProducts = {
    termUnit: 1 | 2;
    isBuyout: boolean;
};

export const getCreditProductsList = async (param?: TParamsCreditProducts): Promise<TCreditProduct[]> => {
    const responseCreditProductsList = await CreditProductsService.read<
        TCreditProductWithMeta,
        TCreditProductBaseParams
    >({
        params: {
            type: PLATFORM_CODE,
            product: PRODUCT_TYPE,
            ...(param?.termUnit && { termUnit: param?.termUnit }),
            ...(param?.isBuyout && { isBuyout: param?.isBuyout }),
        },
    });

    return responseCreditProductsList.payload.data;
};

/*
 * Используется для определения нужно КП по товару
 * TODO Почему функция без запроса на сервер  лежит в API
 */
export const getCreditProductByPrice = (array: TCreditProduct[], productPrice: number): TCreditProduct | null => {
    const creditProduct = array.find((product) => {
        if (product.minAmount <= productPrice && productPrice <= product.maxAmount) {
            return true;
        }

        return false;
    });

    return creditProduct ?? null;
};
