import { isStorageDenied } from '@/shared/utils/check-storage-denied';
import { isJSON } from '@/shared/utils/json';

type TLocalStoreInterface = {
    set: <T>(key: string, data: T) => void;
    get: <T>(key: string) => T;
    remove: (key: string | string[]) => void;
    clear: () => void;
};

export const LocalStore: TLocalStoreInterface = {
    set: (key, data) => {
        if (!isStorageDenied) {
            localStorage.setItem(key, JSON.stringify(data));
        }
    },
    get: (key) => {
        let value: string | null = '';
        if (!isStorageDenied) {
            value = localStorage.getItem(key);
        }
        return isJSON(value) ? JSON.parse(!isStorageDenied ? localStorage.getItem(key)! : '') : value;
    },
    remove: (key) => {
        const keys = Array.isArray(key) ? key : [key];
        if (!isStorageDenied) {
            keys.forEach((key) => localStorage.removeItem(key));
        }
    },
    clear: () => {
        if (!isStorageDenied) {
            localStorage.clear();
        }
    },
};
