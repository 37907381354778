import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { LogoIcon } from '@/shared/ui/icons';

import * as Styled from './styled';

export const ErrorPage500: FC = () => {
    const { reload } = useRouter();
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <LogoIcon color="#1F2547" firstLetterColor="#00D9DE" />
            <div className="description">
                <span className="smile"> 😢</span>
                <div>
                    <div className="title">{t('У нас что-то сломалось')}</div>

                    <div className="text">
                        {t('Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время.')}
                    </div>
                </div>

                <Button variant="primary" onClick={reload} aria-label="Обновить страницу">
                    {t('Обновить страницу')}
                </Button>
            </div>
            <div className="contact">
                <span>{t('callUs', { phone: <b>+7 (700) 007-55-07</b> })} </span>
                <br />
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('waitingForCalls', { from: '<b>9:00</b>', to: '<b>21:00</b>' }),
                    }}
                />
            </div>
        </Styled.Wrapper>
    );
};
