import { TPaginationParams, axiosClientSCClass, TMeta } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';

import { COMMAND_CATEGORIES, COMMAND_PRODUCTS } from '../constants';
import {
    TCategoryProduct,
    TCategoryProductWithMeta,
    TFullRentProduct,
    TGetRentProductsParams,
    TRentProduct,
} from '../types';

export const RentService = ResourceService('v1/rent', axiosClientSCClass);

export type TResponseProductsWithMeta = {
    data: TRentProduct[];
    meta: TMeta;
};

export const getRentProducts = async (params?: TGetRentProductsParams): Promise<TResponseProductsWithMeta> => {
    const responseProducts = await RentService.command<TResponseProductsWithMeta, TGetRentProductsParams>({
        command: COMMAND_PRODUCTS,
        params,
        method: 'GET',
    });

    return responseProducts;
};

export const getAllCategories = async (params?: TPaginationParams): Promise<TCategoryProduct[]> => {
    const responseCategories = await RentService.command<TCategoryProductWithMeta, TPaginationParams>({
        command: COMMAND_CATEGORIES,
        params,
        method: 'GET',
    });

    return responseCategories.data;
};

export const getCategoryBySlug = async (slug: string): Promise<TCategoryProduct> => {
    return await RentService.readOne<TCategoryProduct>({
        identifier: `${COMMAND_CATEGORIES}/${slug}`,
    });
};

export const getProductInformation = async ({ id }: { id: string }): Promise<TFullRentProduct> => {
    const responseProductInformation = await RentService.readOne<TFullRentProduct>({
        identifier: `${COMMAND_PRODUCTS}/${id}`,
    });

    return responseProductInformation;
};
