export enum EStatusProduct {
    ACTIVE = 'active',
    DRAFT = 'draft',
    SOLD = 'sold',
    ONSALE = 'on_sale',
    WRITTENOFF = 'written_off',
    LEASED = 'leased',
    RESERVED = 'reserved',
    CREATED = 'created',
}

export const COMMAND_PRODUCTS = 'products';
export const COMMAND_CATEGORIES = 'categories';
