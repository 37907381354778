import { TErrorData, TReadParamsProperties } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';

export const CallTrackService = ResourceService('calltracks');

export type TResponseUserCallback = {
    phone: string;
    id: number;
    utmSource: string;
};

export const getCalltrackByUtmSource = async (utmSource: string): Promise<TResponseUserCallback | TErrorData> => {
    const phone = await CallTrackService.readOne<TResponseUserCallback, TReadParamsProperties>({
        identifier: utmSource,
    });

    return phone;
};
