import { MessageInfoSmileErrorModal, MessageSmileModal } from '@/components/modules/message-smile-modal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/reducers/modal/slice';

import { UNCAUGHT_COMMON_TEXT_ERROR } from '@/shared/constants/settings';

import { useTranslate } from '../use-translate';

type TReturnedErrorFunc = {
    showError: (message?: string) => void;
    showInfoError: (message: string) => void;
};

export const useShowTechnicalError = (): TReturnedErrorFunc => {
    const dispatch = useAppDispatch();

    const { t } = useTranslate();

    const errorFromState = useAppSelector((state) => state.settings.technicalError);

    const showError = (message?: string) => {
        /* eslint-disable */
        // TODO костыль ARKZ-235
        if (errorFromState && message) {
            console.groupCollapsed('technicalError');
            console.trace('errorTrace');
            console.groupCollapsed('errorFromState');
            console.log(errorFromState.error);
            console.groupEnd();
            console.groupCollapsed('message');
            console.log(message);
            console.groupEnd();
            console.groupEnd();
        } else {
            if (errorFromState) {
                console.groupCollapsed('technicalErrorFromState');
                console.trace('errorTrace');
                console.log(errorFromState.error);
                console.groupEnd();
            }
            if (message) {
                console.groupCollapsed('technicalErrorFromMessage');
                console.trace('errorTrace');
                console.log(message);
                console.groupEnd();
            }
        }

        /* eslint-enable */
        dispatch(
            showModal({
                title: message ? t('Возникла техническая ошибка') : t('Что-то пошло не так'),
                content: (
                    <MessageSmileModal
                        textBtn={`${t('Понятно')}  🤗`}
                        content={message ? t(message) : t(UNCAUGHT_COMMON_TEXT_ERROR)}
                    />
                ),
            }),
        );
    };

    const showInfoError = (message: string) => {
        dispatch(
            showModal({
                title: t('Возникла ошибка'),
                size: 'Small',
                content: <MessageInfoSmileErrorModal textBtn={`${t('Хорошо')}  🤗`} content={message} />,
            }),
        );
    };

    return { showError, showInfoError };
};
