import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TFindCustomerType } from '@/types/user';

import { TErrorData, TReadParamsProperties } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';
import { PRODUCT_TYPE } from '@/shared/constants/settings';
import { regDeleteSpaces } from '@/shared/utils/reg-delete-spaces';

import { axiosClientSCClass } from './axios/index';

export const IinService = ResourceService('v1/iin', axiosClientSCClass);

export type TIinParams = {
    iin: string;
};

export type TIinResponseOk = {
    iin: string;
    found: string;
};

export type TResponseIinIsValid = {
    age: number | null;
    is_valid: boolean;
};

export const checkIinIsValid = async (iin: string): Promise<TResponseIinIsValid | TErrorData> => {
    try {
        const responseIsValidIin = await IinService.command<TResponseIinIsValid, TReadParamsProperties>({
            command: `${iin}/validate`,
        });

        return responseIsValidIin;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};

export const checkUserByIin = async (iin: string): Promise<TFindCustomerType> => {
    const response = await IinService.command<TFindCustomerType, TReadParamsProperties>({
        command: regDeleteSpaces(iin),
        params: {
            product: PRODUCT_TYPE,
        },
        method: 'GET',
    });

    return response;
};

export const checkUserByIinWithValidate = async (iin: string): Promise<TIinResponseOk | TErrorData> => {
    try {
        const data = checkUserByIin(iin);

        return data;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};
