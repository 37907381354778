import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { setTechnicalError } from '@/store/reducers/settings/slice';

import { TResponseCitiesWithMeta } from '@/shared/api';
import { getCities } from '@/shared/api/sass-credit/cities-service';

import { SETTING_ORGANISATION } from './constants';

export const loadCities = createAsyncThunk<TResponseCitiesWithMeta>(
    'cities/loadCities',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            return await getCities({ organizationId: SETTING_ORGANISATION });
        } catch (err) {
            /* eslint-disable no-console */
            console.error('loadCities error', err);

            const error = err as AxiosError;

            captureException(error);

            // TODO костыль ARKZ-235
            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
