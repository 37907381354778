import { TMenuList } from '@/store/reducers/menus/types';

import { ResourceService, TReadParamsProperties } from '../base-services/resource-service';
import { TMeta } from '../base-services/types';

export const MenuService = ResourceService('menus');

type ResponseTypeMenu = {
    meta: TMeta;
    data: TMenuList[];
};

export const getHeaderMenuSettings = async (locale: string): Promise<TMenuList[]> => {
    const { data } = await MenuService.read<ResponseTypeMenu, TReadParamsProperties>({
        params: {
            locale,
        },
    });

    return data;
};
