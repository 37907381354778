import { createSlice } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { TRootStateType } from '@/store';

import { TOfficeState } from './types';

const initialState: TOfficeState = {
    coordinates: null,
};

export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {
        setCoordinates(state, action) {
            state.coordinates = action.payload as TCoordinates;
        },
        restoreCoordinates(state) {
            state.coordinates = null;
        },
    },
});

export const { setCoordinates, restoreCoordinates } = officeSlice.actions;

export const officeReducer = officeSlice.reducer;
export const useLocaleSelector: TypedUseSelectorHook<TRootStateType> = useSelector;
