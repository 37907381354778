import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getSocialLinks } from '@/shared/api';

export const loadSocialLinks = createAsyncThunk<TSocialLink[]>(
    'socialLinks/loadSocialLinks',
    async function (_, { rejectWithValue }) {
        try {
            return await getSocialLinks();
        } catch (err) {
            console.error('loadSocialLinks error', err);
            const error = err as AxiosError;
            captureException(error);

            return rejectWithValue(error);
        }
    },
);
