import { Cookies } from '@/shared/utils/cookie';

import { COOKIE_CALL_TRACK_PHONE } from '../constants';

export const getCallTrackPhoneCookie = (): string => {
    return Cookies.get(COOKIE_CALL_TRACK_PHONE) ?? '';
};

export const setCallTrackPhoneCookie = (phone: string) => {
    const currentDate = new Date();

    currentDate.setMonth(currentDate.getMonth() + 1);
    Cookies.set(COOKIE_CALL_TRACK_PHONE, phone, { expires: currentDate });
};
