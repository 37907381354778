import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { setTechnicalError } from '@/store/reducers/settings/slice';
import { TCreditProduct } from '@/types/credit-products';

import { getCreditProductsList } from '@/shared/api';

export const loadCreditProducts = createAsyncThunk<TCreditProduct[], undefined>(
    'creditProducts/loadCreditProducts',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            return await getCreditProductsList();
        } catch (err) {
            /* eslint-disable no-console */
            console.error('loadCreditProducts error', err);

            const error = err as AxiosError;

            captureException(error);

            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
