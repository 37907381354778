const requireEnv = (variable: string | undefined, name: string) => {
    if (typeof variable === 'undefined') {
        throw new Error(`Env variable "${name}" is required`);
    }

    return variable;
};

export const isClient = (): boolean => typeof window === 'object';
export const isProduction = (): boolean => process?.env.NODE_ENV === 'production';
export const isTest = (): boolean => process?.env.NODE_ENV === 'test';

export const PUBLIC_SITE_SC_BASE_URL = requireEnv(process.env.API_SC_BASE_URL, 'API_SC_BASE_URL');

export const PUBLIC_SITE_API_SERVER_BASE_URL = requireEnv(process.env.API_SERVER_BASE_URL, 'API_SERVER_BASE_URL');

export const PUBLIC_SITE_API_PUBLIC_BASE_URL = requireEnv(process.env.API_PUBLIC_BASE_URL, 'API_PUBLIC_BASE_URL');

export const PUBLIC_SITE_API_AUTH_BASE_URL = requireEnv(process.env.API_AUTH_BASE_URL, 'API_AUTH_BASE_URL');

export const PUBLIC_SITE_SALE_POINT_ID = requireEnv(process.env.SALE_POINT_ID, 'SALE_POINT_ID');
